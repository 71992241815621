import React from 'react';
import { SkewedBg } from '../layout';
import { API_URL } from '../../redux/root-url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

export const TeamMember = (props) => (
  <div class={`${props.className || ''} team-description`}>
    <img
      alt={`Portrait von ${props.firstname} ${props.lastname}`}
      src={'https://www.avionmar.com/' + props.image.path}
    />
    <h5 style={{textAlign: 'center'}}>
      <span translate="no" class="notranslate">
        {props.firstname}
      </span>{' '}
      <span translate="no" class="notranslate">
        {props.lastname}
      </span>
    </h5>
    {/* <span className="job-title white">{props.job}</span> */}
    {/* <div className="more-info">
      <a href={`tel:${props.tel}`}>
        <FontAwesomeIcon icon={faPhone} /> {props.tel}
      </a>{' '}
      <br />
      <a href={`mailto:${props.email}`}>
        <FontAwesomeIcon icon={faEnvelope} />
        {props.email}
      </a>
    </div> */}
  </div>
);
// export const TeamMember = (props) => (
//   <div className={`${props.className || ''}`}>
//     <figure className="team-member">
//       <img
//         src={API_URL + props.image.path || ''}
//         alt={props.firstname + props.lastname}
//       />
//       <figcaption>
//         <SkewedBg>
//           <h6>
//             <span translate="no" class="notranslate">{props.firstname}</span>{' '}
//             <span translate="no" class="notranslate">{props.lastname}</span>
//           </h6>
//           <span className="job-title white">{props.job}</span>
//         </SkewedBg>
//         <div className="more-info">
//           <a href={`tel:${props.tel}`}>{props.tel}</a>
//           <a href={`mailto:${props.email}`}>{props.email}</a>
//         </div>
//       </figcaption>
//     </figure>
//   </div>
// );
